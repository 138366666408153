html {
  position: relative;
  min-height: 100%;
}

#root {
  --principal-blue: #0091da;
  --principal-horizon-border-gray: #ebebeb;
}

body {
  margin: 0 0 calc(185px + 2rem); /* This is the footer offset. Must match footer height + footer top margin */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.comp-hub-disbursements {
  align-items: center;
  justify-content: center;
}

.card-container {
  width: 100%;
  display: table;
  border-spacing: 20px;
}

@media print {
  body {
    width: 100%;
    margin: 0 0 calc(80px + 0.5rem); /* This is the footer offset. Must match footer height + footer top margin */
    float: none;
  }

  /*noinspection CssInvalidFunction*/
  @page {
    size: A4 landscape;
    /*IE Landscape fix*/
    filter: progid:DXImageTransform.Microsoft.BasicImage(Rotation=3);
    margin-bottom: 1.75cm;
  }

  .no-print {
    display: none;
  }

  .table {
    page-break-before: always;
    page-break-inside: auto;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  footer.comp-hub-footer {
    height: 80px;
    margin-top: 0.5rem;
  }

  footer.comp-hub-footer .footer-legal,
  footer.comp-hub-footer .footer-legal a {
    font-size: 0.75rem;
  }

  footer nav {
    display: none;
  }

  .comp-hub-disbursements {
    height: 95%;
  }

  /* I have to use !important against best practices. The util-margin-50 class itself uses !important */
  .util-margin-50 {
    margin: 10px !important;
  }

  .comp-hub-disbursements.util-margin-50 {
    margin-top: 0 !important;
  }
}
