.data-card-container {
  display: flex;
  gap: 10px 20px;
  height: 150px;
  margin: 20px;
}

.data-card-item {
  flex-grow: 1;
  text-align: center;
  border: 1px solid var(--principal-horizon-border-gray);
  width: 100%;
}

.data-card-contents {
  margin-top: 43px;
}

.data-card-amount {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}

.data-card-label {
  font-size: 18px;
  margin-top: 0;
}

.data-card-currency {
  font-size: 18px;
  vertical-align: top;
  line-height: 40px;
  letter-spacing: 5px;
}
