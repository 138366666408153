.notification .close {
  float: right;
}

.alert {
  margin-bottom: 5px;
}

.alert-icon-lg:before {
  margin-bottom: 0;
}
