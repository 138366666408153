.category-Default h5 {
  margin-bottom: 0;
}

.collapsable-tab-button {
  padding: 3px 8px;
  transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
}

.collapsable-tab-button.collapsable-tab-button-collapsed .fa-caret-right {
  margin-left: 0.5em;
}

.collapsable-tab-button.collapsable-tab-button-collapsed .title {
  transform: rotate(90deg);
  line-height: 1em;
  height: 8em;
  width: 8em;
  margin: 0 0 0 -6.5em;
}

.collapsable-tab-button.collapsable-tab-button-collapsed {
  border-radius: 0 5px 5px 0;
  border-left: 0;
  position: absolute;
  top: 15px;
  left: -25px;
}

.collapsable-tab-button.collapsable-tab-button-expanded {
  border-radius: 5px 0 0 5px;
  border-right: 0;
  float: right;
}

/*
*
* utilities
*/
.hub-util-h5-btn-align-vertical {
  margin-top: 2px;
}

.hub-well-border {
  border: 1px solid var(--principal-horizon-border-gray);
  padding-top: 15px;
}
