.percent,
.usd,
.date {
  text-align: right;
}

.usd::before {
  content: '$';
  font-size: 90%;
  letter-spacing: 1px;
  font-family: 'FS Elliot Web Regular', 'FS Elliot Web Regular', 'Arial', arial, sans-serif;
}

.table-overflow {
  overflow-anchor: auto;
}
