.DateRangePickerInput {
  display: block;
}

.DateRangePicker_picker {
  z-index: 999;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #006fa7;
  border: 1px double #006fa7;
  color: #fff;
}

.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: var(--principal-blue);
  border: 1px double #006fa7;
  color: #fff;
}
