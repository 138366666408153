.disbursement-nav {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.disbursement-nav-element {
  width: 101%;
}

.disbursement-nav-arrow-em {
  font-weight: bold;
}

.pay-method {
  padding-top: 0.2em;
}

.line {
  width: 100%;
  margin: 0;
  border-top: 1px solid rgb(200, 200, 200);
}

.pagination {
  display: flex;
  justify-content: center;
}
