.apply-filter {
  float: left;
}

.form-control {
  height: 45px;
}

.search.close {
  display: inline-block;
  float: none;
  vertical-align: middle;
  margin-left: 0.3em;
  color: #fff;
  opacity: 1;
  text-shadow: none;
}

.close:hover {
  color: #f05948;
  opacity: 1;
}

.nav-pills {
  margin-top: 14px;
  margin-bottom: 4px;
}

.magnify {
  z-index: 3;
}
