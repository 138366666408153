thead th:hover {
  background: #f1f1f1;
}

.sort-asc,
.sort-desc,
.sortable {
  cursor: pointer;
}

.sort-asc::after,
.sort-desc::after,
.sortable::after {
  font: normal normal normal 16px/1 FontAwesome;
  padding-left: 10px;
}

.sort-asc::after,
.sort-desc::after {
  color: #035fa4;
  font-size: 20px;
}

.sort-asc::after {
  content: '';
}

.sort-desc::after {
  content: '';
}

.sortable::after {
  content: '';
  color: #464646;
  opacity: 0.3;
}
