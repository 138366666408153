.loading-center {
  margin: 0;
  position: absolute;
  top: 35%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading-spinner {
  font-size: 500%;
  margin-top: 20px;
}
