.comp-hub-footer {
  margin-top: 2rem;
  position: absolute;
  bottom: 0;
  height: 185px;
  width: 100%;
}

.footer-row {
  padding: 30px 0 30px 0;
}

.nav-footer {
  margin-top: 30px;
}
