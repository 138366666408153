.basic-search-form-container {
  display: block;
  text-align: center;
}
.basic-search-form {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.basic-search-input {
  min-width: 16em;
  max-width: 20em;
}

.authorizations-btn {
  min-width: 16em;
}

.selected {
  background-color: #035fa4 !important;
  color: #ffffff !important;
}
