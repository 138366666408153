.RichEditor-container {
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  min-height: 6em;
  cursor: text;
}

.RichEditor-divider {
  border-bottom: 1px solid #c7c7c7;
  margin: 0 25px 0 25px;
}

.StyleButtons-button {
  border: 1px solid #acacac;
}

.StyleButtons-button:active {
  box-shadow: inset 5px 5px 5px rgba(0 0 0 51);
}

.popover-input {
  max-width: 100%;
}

.popover {
  max-width: 350px;
}

.ok-button,
.cancel-button {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
